var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-card',{attrs:{"no-body":""}},[_c('group-form',{attrs:{"visible":_vm.showForm,"resource-id":_vm.resourceId},on:{"saved":function($event){return _vm.fetchList()},"close":_vm.onCloseForm}}),_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('b-button',{class:{
            'icon-disabled': !_vm.$can('PerformAs', 'CompanyAdmin'),
          },attrs:{"id":"create-button","variant":"primary"},on:{"click":function($event){_vm.$can('PerformAs', 'CompanyAdmin') && _vm.create()}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(_vm.$t('Create New')))])],1),_c('b-tooltip',{attrs:{"target":"create-button"}},[_vm._v(" "+_vm._s(_vm.$can('PerformAs', 'CompanyAdmin') ? _vm.$t('Create') : _vm.$t('no-action-permission'))+" ")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex justify-content-end"},[_c('div',[_c('prozess-search-input',{attrs:{"value":_vm.search,"placeholder":_vm.$t('Search')},on:{"input":_vm.handleSearch}})],1)])])],1)],1),_c('div',{staticClass:"m-2"},[_c('b-table',{ref:"refTable",staticClass:"table-responsive",attrs:{"busy":_vm.loading,"items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":_vm.$t('No matching records found'),"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc},on:{"sort-changed":_vm.handleSortChange},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center my-2"},[_c('b-spinner',{staticClass:"align-middle"})],1)]},proxy:true},{key:"head()",fn:function({ label }){return [_c('span',[_vm._v(_vm._s(_vm.$t(label)))])]}},{key:"cell(users)",fn:function({ item }){return [_c('p',{staticClass:"text-nowrap text-center m-0"},[_vm._v(" "+_vm._s(item.userCount)+" ")])]}},{key:"cell(actions)",fn:function({ item }){return [_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
              'icon-disabled': !_vm.$can('PerformAs', 'CompanyAdmin'),
            },attrs:{"id":`edit-row-${item.uuidKey}-preview-icon`,"icon":"EditIcon","size":"16"},on:{"click":function($event){_vm.$can('PerformAs', 'CompanyAdmin') && _vm.edit(item.uuidKey)}}}),_c('b-tooltip',{attrs:{"title":!_vm.$can('PerformAs', 'CompanyAdmin') ? _vm.$t('no-action-permission') : _vm.$t('Edit'),"target":`edit-row-${item.uuidKey}-preview-icon`,"placement":"left"}}),_c('feather-icon',{staticClass:"mx-1 cursor-pointer",class:{
              'icon-disabled': !_vm.$can('PerformAs', 'CompanyAdmin'),
            },attrs:{"id":`delete-row-${item.uuidKey}-preview-icon`,"icon":"TrashIcon","size":"16"},on:{"click":function($event){_vm.$can('PerformAs', 'CompanyAdmin') && _vm.remove(item.uuidKey)}}}),_c('b-tooltip',{attrs:{"title":!_vm.$can('PerformAs', 'CompanyAdmin') ? _vm.$t('no-action-permission') : _vm.$t('Delete'),"target":`delete-row-${item.uuidKey}-preview-icon`,"placement":"left"}})],1)]}}])})],1),(!_vm.loading)?_c('prozess-pagination',{staticClass:"mx-2 mb-2",class:{ 'd-none': !_vm.total },attrs:{"meta":_vm.dataMeta,"page":_vm.currentPage,"total":_vm.total,"size":_vm.perPage},on:{"change":_vm.handleCurrentPage}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }