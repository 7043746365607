<template>
  <b-card no-body>
    <group-form
      :visible="showForm"
      :resource-id="resourceId"
      @saved="fetchList()"
      @close="onCloseForm"
    />
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            id="create-button"
            variant="primary"
            :class="{
              'icon-disabled': !$can('PerformAs', 'CompanyAdmin'),
            }"
            @click="$can('PerformAs', 'CompanyAdmin') && create()"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            <span class="align-middle">{{ $t('Create New') }}</span>
          </b-button>
          <b-tooltip target="create-button">
            {{ $can('PerformAs', 'CompanyAdmin') ? $t('Create') : $t('no-action-permission') }}
          </b-tooltip>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex justify-content-end">
            <div>
              <prozess-search-input
                :value="search"
                :placeholder="$t('Search')"
                @input="handleSearch"
              />
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
    <div class="m-2">
      <b-table
        ref="refTable"
        :busy="loading"
        :items="items"
        responsive
        :fields="tableColumns"
        primary-key="id"
        show-empty
        :empty-text="$t('No matching records found')"
        class="table-responsive"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        @sort-changed="handleSortChange"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle" />
          </div>
        </template>

        <template #head()="{ label }">
          <span>{{ $t(label) }}</span>
        </template>

        <template #cell(users)="{ item }">
          <p class="text-nowrap text-center m-0">
            {{ item.userCount }}
          </p>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="{ item }">
          <div class="text-nowrap">
            <feather-icon
              :id="`edit-row-${item.uuidKey}-preview-icon`"
              icon="EditIcon"
              size="16"
              :class="{
                'icon-disabled': !$can('PerformAs', 'CompanyAdmin'),
              }"
              class="mx-1 cursor-pointer"
              @click="$can('PerformAs', 'CompanyAdmin') && edit(item.uuidKey)"
            />
            <b-tooltip
              :title="!$can('PerformAs', 'CompanyAdmin') ? $t('no-action-permission') : $t('Edit')"
              :target="`edit-row-${item.uuidKey}-preview-icon`"
              placement="left"
            />
            <feather-icon
              :id="`delete-row-${item.uuidKey}-preview-icon`"
              icon="TrashIcon"
              size="16"
              :class="{
                'icon-disabled': !$can('PerformAs', 'CompanyAdmin'),
              }"
              class="mx-1 cursor-pointer"
              @click="$can('PerformAs', 'CompanyAdmin') && remove(item.uuidKey)"
            />
            <b-tooltip
              :title="!$can('PerformAs', 'CompanyAdmin') ? $t('no-action-permission') : $t('Delete')"
              :target="`delete-row-${item.uuidKey}-preview-icon`"
              placement="left"
            />
          </div>
        </template>
      </b-table>
    </div>
    <prozess-pagination
      v-if="!loading"
      class="mx-2 mb-2"
      :class="{ 'd-none': !total }"
      :meta="dataMeta"
      :page="currentPage"
      :total="total"
      :size="perPage"
      @change="handleCurrentPage"
    />
  </b-card>
</template>

<script>
import { swalConfirmDeleteOption } from '@/helpers/app'
import listMixinFactory from '@/mixins/list'
import groupService from '@/services/group'
import settingsService from '@/services/settings'
import GroupForm from './Form.vue'

export default {
  components: {
    GroupForm,
  },
  mixins: [
    listMixinFactory({
      routeName: 'groups-settings',
      service: groupService,
      tableColumns: [
        { key: 'name', sortable: true },
        { key: 'email', sortable: true },
        { key: 'membersCount', label: 'Users', class: 'text-center disabled' },
        { key: 'actions', class: 'text-center disabled' },
      ],
      searchField: 'name',
      sort: {
        name: 'name',
        email: 'email',
      },
    }),
  ],
  data() {
    return {
      resourceId: null,
      showForm: false,
      userGroupId: null,
    }
  },
  created() {
    this.getInquiryUserGroupId()
  },
  methods: {
    async getInquiryUserGroupId() {
      const { response: settingsResponse } = await this.$async(settingsService.getOne('inquiry'))
      this.userGroupId = settingsResponse?.data
    },
    create() {
      this.resourceId = null
      this.showForm = true
    },
    edit(id) {
      this.resourceId = id
      this.showForm = true
    },
    remove(uuidKey) {
      this.$swal(swalConfirmDeleteOption(v => this.$t(v))).then(async result => {
        if (result.value) {
          this.deleteResource(uuidKey)
          if (uuidKey === this.userGroupId) {
            settingsService.patch({ inquiry: '' })
          } // reset inquiry settings if same key
        }
      })
    },
    onCloseForm() {
      this.resourceId = null
      this.showForm = false
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
