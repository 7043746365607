<template>
  <prozess-sidebar-modal-wrapper
    :visible="visible"
    form-icon="PlusIcon"
    :form-title="formTitle"
    :loading="resourceId && loading"
    :saving="saving"
    save-icon="SaveIcon"
    save-label="Save changes"
    @close="close"
    @submit="save"
  >
    <form
      id="formSidebar"
      autocomplete="off"
      class="m-2 tw-pt-2"
      style="flex: 1"
      @submit.prevent
    >
      <b-form-group label-for="vi-first-name">
        <prozess-input
          v-model="resource.name"
          :placeholder="$t('Name')"
          field="name"
          icon="UserIcon"
          :error="$hasError('name')"
          @enter="save"
        />
        <prozess-input
          v-model="resource.email"
          :placeholder="$t('Email')"
          icon="MailIcon"
          field="email"
          name="email"
          :error="$hasError('email')"
          @enter="save"
        />
        <b-input-group class="input-group-merge mb-2 tw-pt-3 form-sidebar__add-user">
          <b-input-group-prepend is-text>
            <feather-icon icon="UserPlusIcon" />
          </b-input-group-prepend>
          <v-select
            v-model="selectedUser"
            style="flex: 1"
            :placeholder="$t('Add User')"
            :options="users"
            :get-option-label="option => option.firstName + ' ' + option.lastName"
            @input="selectUser"
            @search="onSearchUser"
            @search:blur="onBlurSearchUser"
          >
            <span slot="no-options">
              <span v-if="searchText.length">{{ $t(`No results found`) }}</span>
              <span v-else>{{ $t(`Search by name`) }}</span>
            </span>
          </v-select>
        </b-input-group>
      </b-form-group>
      <div class="form-sidebar__users tw-pt-3">
        <div class="p-1 form-sidebar__users-header">
          <h3>{{ $t('Users') }}</h3>
        </div>
        <div class="form-sidebar__user-list">
          <div
            v-if="selectedUsers.length === 0"
            class="p-1"
          >
            {{ $t('No users.') }}
          </div>
          <div
            v-for="user of selectedUsers"
            :key="user.userUuid"
            class="form-sidebar__user p-1 d-flex justify-content-between"
          >
            <p>{{ user.firstName + ' ' + user.lastName }}</p>
            <div @click="removeUser(user)">
              <feather-icon
                icon="XIcon"
                class="text-white tw-cursor-pointer bg-primary tw-rounded-full tw-flex tw-justify-center tw-items-center"
                style="padding: 2px"
                size="16"
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  </prozess-sidebar-modal-wrapper>
</template>

<script>
import vSelect from 'vue-select'
import { debounce } from 'lodash'
import employeeService from '@/services/employee'
import groupService from '@/services/group'

export default {
  components: {
    vSelect,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    resourceId: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      resource: {},
      users: [],
      selectedUser: null,
      selectedUsers: [],
      saving: false,
      loading: false,
      errors: [],
      searchText: '',
    }
  },
  computed: {
    formTitle() {
      return this.resourceId ? 'Edit Group' : 'Create New Group'
    },
  },
  watch: {
    visible(value) {
      if (value) {
        this.reset()
        if (this.resourceId) this.getOne(this.resourceId)
      }
    },
  },
  methods: {
    onBlurSearchUser() {
      this.users = []
    },
    removeUser(selectedUser) {
      this.selectedUsers = this.selectedUsers.filter(user => user.userUuid !== selectedUser.userUuid)
    },
    selectUser(selectedUser) {
      this.users = []
      if (!selectedUser) return
      if (this.selectedUsers.some(user => user.userUuid === selectedUser.userUuid) === false) {
        this.selectedUsers.push(selectedUser)
      }
      this.selectedUser = null
    },
    reset() {
      this.users = []
      this.resource = {}
      this.selectedUsers = []
      this.errors = []
    },
    getOne(id) {
      this.loading = true
      groupService
        .getOne(id)
        .then(res => {
          this.resource = res.data
          this.selectedUsers = this.resource.members
        })
        .finally(() => {
          this.loading = false
        })
    },
    close() {
      this.$emit('close')
    },
    save() {
      this.errors = []
      this.saving = true
      this.resource.usersUuids = this.selectedUsers.map(user => user.userUuid)
      this.resource.name = this.resource.name === '' ? null : this.resource.name
      this.resource.email = this.resource.email === '' ? null : this.resource.email
      this.createOrUpdate(this.resource)
        .then(res => {
          this.$emit('saved')
          this.close()
        })
        .catch(err => {
          this.errors = this.$lodash.get(err, 'response.data.errors', [])
        })
        .finally(() => {
          this.saving = false
        })
    },
    createOrUpdate(data) {
      return this.resourceId ? groupService.update(this.resourceId, data) : groupService.store(data)
    },
    onSearchUser(search, loading) {
      this.searchText = search
      if (search.length) {
        loading(true)
        this.searchUser(search, loading, this)
      }
    },
    searchUser: debounce(async (search, loading, vm) => {
      const res = await employeeService.searchEmployee(search)
      vm.users = res.data
      loading(false)
    }, 1000),
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/form-sidebar.scss';
</style>
